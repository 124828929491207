import request from '../utils/request'

function login(data: any) {
  return request({
    url: '/auth/login',
    method: 'post',
    data
  })
}

function signUp(data: any) {
  return request({
    url: '/sign_up',
    method: 'post',
    data
  })
}

function logout() {
  return request({
    url: '/sessions',
    method: 'delete',
  })
}

function me() {
  return request({
    url: `/users/profile`,
    method: 'get',
  })
}

function updateProfile(data: Object) {
  return request({
    url: `/users/profile`,
    method: 'put',
    data
  })
}

function updatePassword(data: any) {
  return request({
    url: '/users/update_password',
    method: 'post',
    data
  })
}

function forgotPassword(data: any) {
  return request({
    url: '/forgot_password',
    method: 'post',
    data,
  })
}

function resetPassword(data: any, key: any) {
  return request({
    url: `/forgot_password/${key}`,
    method: 'put',
    data
  })
}

export {
  login,
  logout,
  signUp,
  me,
  updateProfile,
  updatePassword,
  forgotPassword,
  resetPassword
}
