import { FC, ReactElement } from 'react'
import { RouteProps } from 'react-router'
import { RootStateOrAny, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import tokenService from '../utils/tokenService'
import { useSearchParams } from 'react-router-dom'
// import PropertyPage from '../pages/home'

const SessionRouteWrapper: FC<RouteProps> = props => {
  const verificationPath = '/session/verification'
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const { logged } = useSelector((state: RootStateOrAny) => state.user)
  const accessToken = tokenService.getAccessToken()
  const pathname = window.location.pathname

  setTimeout(() => {
    logged && navigate('/home')
  }, 0)

  if (logged) {
    logged && navigate('/home')
  } else {
    if (accessToken && !logged && pathname !== verificationPath && !token) {
      navigate(verificationPath)
    }
  }

  return !logged ? (
    (props.element as ReactElement)
  ) : (
    <> </>
  )
}

export default SessionRouteWrapper
