import { Key, lazy, useCallback, useEffect, useState} from 'react'
import { productStore } from '../../redux/store/property.store'
import { Col, Row, Card, Typography, Empty } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../../styles/explore.module.scss'
import InfiniteScroll from 'react-infinite-scroll-component'
import { capitalizeFirstLetter, getUnitName } from '../../utils/common'
import useDocumentTitle from '../../hooks/useDocumentTitle'
import { useQueryParams } from '../../components/common/useQueryParams'
import styles2 from '../collections/collections.module.scss'
import { Tag } from 'antd'
import { InboxOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router'

const CardLoading = lazy(() => import('../../components/common/CardLoading'))
const {Text} = Typography
const { Meta } = Card
export const PAGE_SIZE = 200

const ProductList = () => {
  const dispatch = useDispatch()
  const {products, loading, pagination, totalRecords, address, searchStr, typeId, types}: any = useSelector((state: any) => state.property)
  const [clearTime, setClearTime] = useState<any>()
  const shop = localStorage.getItem('shop')
  const id = useQueryParams('id')
  const navigate = useNavigate()
  const [type, setType] = useState('')
  useDocumentTitle('Tile List')

  console.log('product: ', products)
  useEffect(() => {
    getInitData({}, false)
  }, [location?.search, address, shop])

  const getInitData = async(q: any, filter: any) => {
    if (products?.length < 1 || filter) {
      await dispatch(productStore.loadingToggle(true))
      await dispatch(productStore.resetProduct())
      await dispatch(productStore.getProductList({isPublished: true, type, search: searchStr, shop, size: id, page: 1, limit: PAGE_SIZE, ...q}))
      await dispatch(productStore.loadingToggle(false))
    }
  }

  useEffect(() => {
    !types?.length && dispatch(productStore.getTypes({size: id}))
  }, [])

  const getData = async(query: any) => {
    clearTimeout(clearTime)
    try {
      await dispatch(productStore.loadingToggle(true))
      await dispatch(productStore.resetProduct())
      await dispatch(productStore.getProductList({...query}))
      await dispatch(productStore.loadingToggle(false))
    } catch (error) {
      await dispatch(productStore.loadingToggle(false))
    }
  }
  const applyFilter = async(id: any) => {
    const q = {
      type: id
    }
    getInitData(q, true)
  }

  const fetchMoreData = useCallback(() => {
    clearTimeout(clearTime)
    const page = pagination.page + 1
    const timeOut = setTimeout(() => {
      const q = {
        page
      }
      dispatch(productStore.setSearchQuery(q))
      getData(q)
    }, 500)
    setClearTime(timeOut)
  }, [dispatch, getData, pagination, searchStr])

  useEffect(() => {
    if (searchStr) {
      const q = {
        page: 1,
        search: searchStr,
      }
      dispatch(productStore.setSearchQuery(q))
      getInitData(q, false)
    } else {
      const q = {
        page: 1,
      }
      dispatch(productStore.setSearchQuery(q))
      getInitData(q, false)
    }
  }, [searchStr])

  // if (loading && !products?.length) {
  //   return (
  //     <Row className={styles['loading-controler']} gutter={[20, 20]}>
  //       {
  //         [0, 1, 2, 3].map((index) => {
  //           return <Col xl={6} lg={8} md={12} sm={12} xs={12} key={index}>
  //             <CardLoading />
  //           </Col>
  //         })
  //       }
  //     </Row>
  //   )
  // }

  return (
    <div className={'layout-page-content-style'}>
      <div className={styles2['header']}>
        <div className={styles2['max-width']}>
          <Tag onClick={ () => {
            setType('')
            getInitData({type: ''}, true)
            dispatch(productStore.resettypeId())
          } } key={'all'} className={ !type ? styles2['custom-tag-active'] : styles2['custom-tag']}>All</Tag>
          {types?.map((el: any) => {
            return <>
              <Tag
                onClick={ () => {
                  applyFilter(el?._id)
                  dispatch(productStore.setTypeId(el?._id))
                  setType(el?._id)
                } }
                key={el?._id}
                className={el?._id !== typeId ? `${styles2['custom-tag']}` : `${styles2['custom-tag-active']}`}
              >
                { capitalizeFirstLetter(el?.name)}
              </Tag>
            </>
          })}
        </div>
      </div>
      {
        loading && !products?.length ? <Row className={styles['loading-controler']} gutter={[20, 20]}>
          {
            [0, 1, 2, 3].map((index) => {
              return <Col xl={6} lg={8} md={12} sm={12} xs={12} key={index}>
                <CardLoading />
              </Col>
            })
          }
        </Row>
          : <InfiniteScroll
            style={{ overflow: 'hidden' }}
            dataLength={products?.length}
            next={fetchMoreData}
            hasMore={totalRecords > products?.length}
            loader={
              (<Row className={styles['loading-controler']} gutter={[20, 20]}>
                {
                  [0, 1, 2, 3].map((index) => {
                    return <Col xl={6} lg={8} md={12} sm={12} xs={12} key={index}>
                      <CardLoading />
                    </Col>
                  })
                }
              </Row>)
            }
          >
            { !products?.length ? <Row className={styles2['no-data']} gutter={[20, 20]}> <Empty /> </Row> : null }
            <div className={styles2['max-width-c']}>

              <Row gutter={[10, 10]} className={styles2['collection-controller']}>
                {
                  products?.map((element: any, index: Key | null | undefined) => {
                    return <Col className='t-list-controller' xl={6} lg={8} md={12} sm={12} xs={12} key={index}>
                      <Card
                        loading={loading}
                        className='t-list'
                        cover={
                          element?.images?.length
                            ? <img loading='lazy' className={styles2['card-image']} alt="example" src={element?.images?.[0]}/>
                            : <img className={styles2['card-image']} alt="example" src={`https://placehold.co/1000x800?text=${element?.size?.name}`} />
                        }
                        actions={[
                          <div className={styles2['actions']}>
                            <div className={styles2['primary-attributes']}>
                              {/* <Text>{ element?.size?.name }</Text> */}
                              {/* <Text className={styles2['type']}>{element?.type?.name}</Text> */}
                            </div>
                            <div className={styles2['footer']}>
                              <div className={styles2['quantity']}>
                                <InboxOutlined />
                                <Text> {element?.quantity} / {getUnitName(element?.productType?.toLowerCase())} IN STOCK </Text>
                              </div>
                              {/* <div className={styles2['price']}>
                                <Title level={4} className='no-margin'>₹{addCommaAfterThreeDigits(element?.price)}</Title>
                                <Text>/Box</Text>
                              </div> */}
                            </div>
                          </div>
                        ]}
                      >
                        <Meta
                          title={<div className={styles2['title-controller']}>
                            {element?.code}
                            <InfoCircleOutlined />
                          </div>}
                          description={element?.type?.name}
                        />
                        <div className={styles2['touch-controller']} onClick={ () => { navigate('/list/product?id=' + element?._id) } } />
                      </Card>
                    </Col>
                  })
                }
              </Row>
            </div>
          </InfiniteScroll>
      }
    </div>
  )
}

export default ProductList
