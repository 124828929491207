import { createSlice, PayloadAction, Dispatch } from '@reduxjs/toolkit'
import { Role } from '@/interface/user/login'
import { Locale, UserState } from '@/interface/user/user'
import { createAsyncAction } from '../utils'
import { getGlobalState } from '../../utils/getGloabal'
import * as authAPI from '../../api/auth'
import { encryptData, decryptData } from '../../utils/crypto'

const initialState: UserState = {
  ...getGlobalState(),
  locale: (localStorage.getItem('locale')! || 'en_US') as Locale,
  logged: !!localStorage.getItem('Token'),
  verified: decryptData('status'),
  role: 'user1' as Role,
  profileInfo: {},
  loader: false,
  kycStatus: ''
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserItem(state, action: PayloadAction<Partial<UserState>>) {
      Object.assign(state, action.payload)
    },
    updateUser(state, action) {
      Object.assign(state.profileInfo, action.payload)
    }
  },
})

export const { setUserItem, updateUser } = userSlice.actions
export default userSlice.reducer

export const login = createAsyncAction((payload) => {
  return async dispatch => {
    try {
      const result = await authAPI.login({mobile: payload.mobile, password: payload.password})
      if (result) {
        localStorage.setItem('Token', result.data.token)
        return true
        // const user = await authAPI.me()
        // dispatch(
        //   setUserItem({
        //     logged: true,
        //     verified: user.data.user.email_verified,
        //     profileInfo: user?.data?.user
        //   }),
        // )
        // if (user.data.user.email_verified) {
        //   encryptData('status', user.data.user.email_verified)
        //   return true
        // } else {
        //   return false
        // }
      }
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const updateProfile = createAsyncAction((payload) => {
  return async dispatch => {
    try {
      const response = await authAPI.updateProfile(payload)
      dispatch(setUserItem({
        profileInfo: response?.data?.user
      }))

      return true
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const me = createAsyncAction((payload) => {
  return async dispatch => {
    dispatch(setUserItem({logged: true}))
    // try {
    //   dispatch(setUserItem({ loader: true }))
    //   const user = await authAPI.me()
    //   dispatch(setUserItem({ loader: false }))
    //   if (user.data.user.email_verified) {
    //     dispatch(setUserItem({
    //       verified: user.data.user.email_verified,
    //       profileInfo: user?.data?.user,
    //       kycStatus: '',
    //     }))
    //     encryptData('status', user.data.user.email_verified)
    //     return true
    //   } else {
    //     return false
    //   }
    // } catch (error: any) {
    //   dispatch(setUserItem({
    //     loader: false
    //   }))
    //   throw error?.response?.data
    // }
  }
})

export const signUp = createAsyncAction((payload) => {
  return async dispatch => {
    try {
      const result = await authAPI.signUp(payload)
      if (result) {
        localStorage.setItem('Token', result.data.token)
        dispatch(
          setUserItem({
            logged: true,
            verified: false,
          }),
        )
      }
      return true
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const logout = () => {
  return async(dispatch: Dispatch) => {
    try {
      // await authAPI.logout()
      localStorage.clear()
      dispatch(
        setUserItem({
          logged: false,
          verified: false,
        })
      )
      return true
    } catch (error: any) {
      throw error?.response?.data
    }
  }
}

export const updatePassword = createAsyncAction((payload) => {
  return async(dispatch: Dispatch) => {
    try {
      await authAPI.updatePassword(payload)
      localStorage.clear()
      dispatch(
        setUserItem({
          logged: false,
          verified: false,
        })
      )
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const setKycStatus = createAsyncAction((payload) => {
  return async dispatch => {
    try {
      dispatch(setUserItem({
        kycStatus: payload
      }))
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const forgotPassword = createAsyncAction((payload) => {
  return async() => {
    try {
      await authAPI.forgotPassword(payload)
      return true
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const resetPassword = createAsyncAction((data : any, key: any) => {
  return async() => {
    try {
      await authAPI.resetPassword(data, key)
      return true
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})

export const refreshProfile = createAsyncAction((payload) => {
  return async dispatch => {
    try {
      const user = await authAPI.me()
      if (user.data.user.email_verified) {
        dispatch(setUserItem({
          verified: user.data.user.email_verified,
          profileInfo: user?.data?.user,
          kycStatus: '',
        }))
        encryptData('status', user.data.user.email_verified)
        return true
      } else {
        return false
      }
    } catch (error: any) {
      throw error?.response?.data
    }
  }
})
