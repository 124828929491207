import CryptoJS from 'crypto-js'

export const encryptData = (name: any, data: any) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY || '').toString()
  encrypted && localStorage.setItem(name, encrypted)
}

export const decryptData = (name: any) => {
  const encrypted: any = localStorage.getItem(name)
  if (encrypted) {
    const decrypted = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_SECURE_LOCAL_STORAGE_HASH_KEY || '').toString(CryptoJS.enc.Utf8)
    return JSON.parse(decrypted)
  }
}
