import { lazy } from 'react'
import { Navigate, useRoutes } from 'react-router-dom'
import Loadable from '../components/common/loader/Loadable'
import PublicRoutes from './publicRoutes'
import privateRoutes from './privateRoutes'
import sessionRoutes from './sessionRoutes'

const NotFound = Loadable(lazy(() => import('../pages/404/404')))
const LayoutPage = Loadable(lazy(() => import('../pages/layout')))
const noHeader = ['/list/product', '/login']
const RenderRouter = () => {
  const routeList = [
    {
      element: <LayoutPage head={!noHeader.includes(window.location.pathname)} />,
      children: [
        ...sessionRoutes,
        ...privateRoutes,
        ...PublicRoutes,
      ],
    },
    {
      path: '/',
      element: <Navigate to="/explore" />,
    },
    {
      path: '*',
      element: <NotFound />,
    },
  ]

  const element = useRoutes(routeList)
  return element
}

export default RenderRouter
