import request from '../utils/request'

function getProducts(params: any) {
  params.shop = localStorage.getItem('shop')
  return request({
    url: '/area',
    method: 'get',
    params
  })
}

function removeNullUndefined(obj: any) {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
      delete obj[key]
    }
  }
  return obj
}

function getProductList(data: any) {
  const params = removeNullUndefined(data)
  return request({
    url: `/tiles/`,
    method: 'get',
    params
  })
}
function getCategories() {
  return request({
    url: `/categories`,
    method: 'get'
  })
}

function getTypes(params: any) {
  return request({
    url: `/types`,
    method: 'get',
    params
  })
}

function getSingleProduct(id: String) {
  return request({
    url: `/tiles/` + id,
    method: 'get'
  })
}

export {
  getProducts,
  getProductList,
  getCategories,
  getSingleProduct,
  getTypes,
}
