import { lazy } from 'react'
import Loadable from '../components/common/loader/Loadable'
import SessionRouteWrapper from './sessionRouteConfig'
const LoginPage = Loadable(lazy(() => import('../pages/login')))
const Signup = Loadable(lazy(() => import('../pages/signup')))

const sessionRoutes = [
  {
    children: [{
      path: '/login',
      element: <SessionRouteWrapper element={<LoginPage />} />,

    },
    {
      path: '/signup',
      element: <SessionRouteWrapper element={<Signup />} />,
    }]
  }
]

export default sessionRoutes
