async function register() {
  if ('serviceWorker' in navigator) {
    try {
      await navigator.serviceWorker.register('/service-worker.js')
      console.log('Service worker registered')
    } catch (error) {
      console.error('Error registering service worker:', error)
    }
  }
}

export { register }
