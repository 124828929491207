import { PAGE_SIZE } from '../../pages/home/list'
import * as propertiesAPI from '../../api/product'
import { createAsyncAction } from '../utils'
import { createSlice } from '@reduxjs/toolkit'
import { sortByFormula } from '../../utils/common'

const propertyStore = createSlice({
  name: 'property',
  initialState: {
    list: [],
    property: {},
    timelines: [],
    documents: [],
    timelinePagination: {},
    documentPagination: {},
    loading: false,
    categories: [],
    typeId: '',
    interestedProperties: [],
    searchStr: '',
    types: [],
    filters: [],
    totalRecords: 0,
    products: [],
    pagination: {
      limit: 1,
      size: PAGE_SIZE,
    },
  },

  reducers: {
    setData(state, action) {
      Object.assign(state, action.payload)
    },
    getProducts: (state: any, action: any) => {
      state.list = action?.payload?.list?.properties
    },
    loadingToggle: (state: any, action: any) => {
      state.loading = action?.payload?.loading
    },
    getProduct: (state: any, action: any) => {
      state.property = action?.payload?.property
    },
    handlePagination: (state: any, action: any) => {
      state.pagination = action?.payload?.pagination
    },
    getCategories: (state: any, action: any) => {
      state.categories = action?.payload?.categories
    },
    addProperties: (state, action) => {
      state.products.push(...action.payload)
    },
  }
})

export const { setData, getProducts, getProduct, loadingToggle, getCategories, handlePagination, addProperties} = propertyStore.actions

export const productStore = {

  getProductList: createAsyncAction(payload => {
    return async dispatch => {
      try {
        const result = await propertiesAPI.getProductList(payload)
        const updatedRes = sortByFormula(result?.data?.data)
        dispatch(setData({products: updatedRes}))
        dispatch(setData({ totalRecords: result?.data?.pagination?.total_records }))
      } catch (error) {
        //
      }
    }
  }),

  getTiles: createAsyncAction(payload => {
    return async dispatch => {
      try {
        const result = await propertiesAPI.getProducts(payload)
        dispatch(setData({list: result?.data?.data?.reverse()}))
        dispatch(setData({ totalRecords: result?.data?.pagination?.total_records }))
      } catch (error) {
        //
      }
    }
  }),

  resetProduct: createAsyncAction(payload => {
    return async dispatch => {
      dispatch(setData({products: []}))
    }
  }),

  resetTypes: createAsyncAction(payload => {
    return async dispatch => {
      dispatch(setData({types: []}))
    }
  }),

  resettypeId: createAsyncAction(payload => {
    return async dispatch => {
      dispatch(setData({typeId: []}))
    }
  }),

  setSearchQuery: createAsyncAction((payload) => {
    return async dispatch => {
      dispatch(setData({
        pagination: payload
      }))
    }
  }),

  // getProduct: createAsyncAction(payload => {
  //   return async dispatch => {
  //     try {
  //       let result = {
  //         data: []
  //       }
  //       if (payload) {
  //         result = await propertiesAPI.getProduct(payload)
  //       }
  //       dispatch(getProduct({ property: result?.data?.property}))
  //     } catch (error) {
  //       //
  //     }
  //   }
  // }),

  loadingToggle: createAsyncAction(payload => {
    return async dispatch => {
      dispatch(loadingToggle({loading: payload}))
    }
  }),

  setSearchStr: createAsyncAction(payload => {
    return async dispatch => {
      dispatch(setData({searchStr: payload}))
    }
  }),

  handlePagination: createAsyncAction(payload => {
    return async dispatch => {
      dispatch(handlePagination({ pagination: payload }))
    }
  }),

  setAddress: createAsyncAction((payload) => {
    return async dispatch => {
      try {
        dispatch(setData({address: payload}))
      } catch (error) {
        console.log("error", error)
      }
    }
  }),

  getTypes: createAsyncAction(payload => {
    return async dispatch => {
      try {
        const result = await propertiesAPI.getTypes(payload)
        dispatch(setData({types: result?.data?.data}))
      } catch (error) {
        //
      }
    }
  }),

  setTypeId: createAsyncAction(payload => {
    return async dispatch => {
      try {
        dispatch(setData({typeId: payload}))
      } catch (error) {
        //
      }
    }
  })
}

export default propertyStore.reducer
