
const Loading = ({height = '150px', width = '150px'}) => {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      width: '98vw',
      height: '97vh',
      alignItems: 'center'
    }}>

    </div>
  )
}

export default Loading
