import axios from 'axios'
import TokenService from '../utils/tokenService'

const baseURL: string = process.env.REACT_APP_API_BASE_URL as string

const service = axios.create({
  baseURL,
  timeout: 100000,
})

service.interceptors.request.use(
  config => {
    if (localStorage.getItem('Token')) {
      config!.headers!['Authorization'] = 'Bearer ' + TokenService.getAccessToken()
    }
    // config!.headers!['ngrok-skip-browser-warning'] = true
    config!.headers!['Access-Control-Allow-Origin'] = '*'
    config!.headers!['Access-Control-Allow-Methods'] = 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
    config!.headers!['Accept-Language'] = 'en'
    return config
  },

  error => {
    console.log(error)
    Promise.reject(error)
  },
)

service.interceptors.response.use(
  response => response,
  async err => {
    if (err?.response?.data?.error === 'Your Session has been Expired') {
      localStorage.getItem('Token')
      window.location.reload()
      window.location.href = '/login'
    }
    return Promise.reject(err)
  },
)

export default service
