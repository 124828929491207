const en = {
  "header.portfolio": 'Portfolio',
  "header.explore": 'Explore',
  "header.link": 'Link',
  "header.details": 'details',
  "header.properties": 'properties',
  "header.transaction": 'transaction',
  "header.notFount": '4O4',
  "header.profileSetting": 'Profile Settings',
  "header.profile": 'Profile',
  "header.transactions": 'Transactions',
  "header.KYC": 'KYC',

  "header.login": 'Login',
  "header.signup": 'Sign up',
  "header.logout": 'Sign out',
  "header.forgotpassword": 'Forgot Password',
  "header.accountactivation": 'Account Activation',
  "header.accountverified": 'Account Verified',

  "4o4.pageNotFound": "Page Not Found",
  "4o4.goBack": "Go Back",

  "login.header": 'Sign in to your account',
  "login.email": 'Email',
  "login.userName": "Enter email",
  "login.password": "Enter password",
  "login.footer": "Don't have an account?",
  "login.cta": "Sign in",
  "login.desc": "Enter the fields below to get started",
  "login.remember": "Remember Me",
  "login.forgotPassword": "Forgot Password?",
  "signup.header": 'Sign up for an account',
  "signup.firstName": "First name",
  "signup.lastName": "Last name",
  "signup.confirmPassword": "Confirm password",
  "signup.footer": "Already have an account?",
  "signup.cta": "Sign up",
  "signup.desc": "Enter the fields below to get started",
  "signup.enterFirstName": "Enter first name",
  "signup.enterLastName": "Enter last name",
  "signup.enterConfirmPassword": "Enter confirm password",
  "signup.enterPassword": "Enter password",
  "signup.enterEmail": "Enter email",

  "global.loading": 'Loading..',
  "global.norecoedfound": 'No Tiles Found.',
  "global.unauthorized": 'Sorry, you are not authorized for this route.',
  "global.goToLogin": 'Go to login',

  "validation.firstname": 'Please enter first name.',
  "validation.email": 'Please enter a valid email.',
  "validation.spacesNotAllowed": "Spaces are not allowed!",
  "validation.password": 'Please enter password.',
  "validation.Newpassword": 'Please enter a new password.',
  "validation.phone": 'Please enter a valid contact number.',
  "validation.date": 'Please enter date.',
  "validation.dob": 'Please enter date of birth.',
  "validation.message": 'Please enter your message.',
  "validation.lastname": 'Please enter last name.',
  "validation.pinCode": 'Please enter the postal or zip code.',
  "validation.confirmpassword": 'Please enter confirm password.',
  "validation.confirmpasswordnotmatched": 'Password and confirm password not matched.',
  "validation.confirmpasswordnotmatchedUpdate": 'New password and confirm password not matched.',
  "validation.oldpasswordmatched": 'Old password and new password can not same.',
  "validation.passwordlength": 'Password must be at least 6 characters long.',
  "validation.country": 'Please select country name.',
  "validation.state": 'Please select a state.',
  "validation.city": 'Please select a suburb.',
  "validation.address": 'Please enter address.',
  'validation.verify': 'Verify',
  'validation.CTA': 'Finish',
  'validation.passwordNote': 'Password must contain at least 8 characters including uppercase, lowercase, numbers, and special characters.',
  "validation.mobileNumberNote": "The contact number must begin with '0' and consist of exactly 10 digits, eg 0437123456.",
  "validation.specialCharNotAllowed": "Special characters are not allowed!",
  "validation.oldPasswordNote": "Please enter old password."
}

export default en
