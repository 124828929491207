// import { lazy } from 'react'
// import Loadable from '../components/common/loader/Loadable'
// import { Navigate } from 'react-router'
// const PropertyPage = Loadable(lazy(() => import('../pages/home')))

const PublicRoutes = [
  {
    children: [
      // {
      //   path: '/',
      //   element: <Navigate to="home" />
      // },
      // {
      //   path: '/home',
      //   element: <PropertyPage />
      // },
    ]
  }
]

export default PublicRoutes
