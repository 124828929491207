class TokenService {
  getAccessToken() {
    return localStorage.getItem('Token')
  }

  setAccessToken(accessToken: string) {
    localStorage.setItem('Token', accessToken)
  }

  removetAccessToken() {
    localStorage.removeItem('Token')
  }

  getRefreshToken() {
    return localStorage.getItem('refreshToken')
  }

  setRefreshToken(refreshToken: string) {
    localStorage.setItem('refreshToken', refreshToken)
  }

  removetRefreshToken() {
    localStorage.removeItem('refreshToken')
  }
}

export default new TokenService()
