import { useLocation } from 'react-router-dom'

/**
 * Custom hook to extract the value of a specific query parameter from the URL.
 * @param {string} query - The name of the query parameter to retrieve.
 * @returns {string | null} - The value of the specified query parameter, or null if not found.
 */
export function useQueryParams(query: string): string | null {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  return queryParams.get(query)
}
