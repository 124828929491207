import { Suspense, useEffect, FC, useState } from 'react'
import { IntlProvider } from 'react-intl'
import { localeConfig, LocaleFormatter } from './locales'
import { Button, ConfigProvider, Modal, Spin, Typography, theme as a } from 'antd'
import enUS from 'antd/es/locale/en_US'
import zhCN from 'antd/es/locale/it_IT'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import RenderRouter from './routes'
import { useSelector } from 'react-redux'
import { RootStateOrAny } from 'react-redux'
import { useDispatch } from 'react-redux'
import { me } from './redux/store/user.store'
import TokenService from './utils/tokenService'
import useDocumentTitle from './hooks/useDocumentTitle'
import variables from './styles/_vars.scss'
const {Title } = Typography

const App: FC = ({installApp}: any) => {
  localStorage.setItem('locale', 'en_US')
  const { locale } = useSelector((state: RootStateOrAny) => state.user)
  const { theme, loading } = useSelector((state: RootStateOrAny) => state.global)
  const [installing, setInstalling] = useState(false)
  const [installedApp, setInstallApp] = useState(false)
  const dispatch = useDispatch()
  useDocumentTitle('')

  // set the locale for the user
  // more languages options can be added here
  useEffect(() => {
    if (locale === 'en_US') {
      dayjs.locale('en')
    } else if (locale === 'it_IT') {
      dayjs.locale('zh-cn')
    }
  }, [locale])

  useEffect(() => {
    TokenService.getAccessToken() && dispatch(me())
  }, [])

  /**
   * handler function that passes locale
   * information to ConfigProvider for
   * setting language across text components
   */
  const getAntdLocale = () => {
    if (locale === 'en_US') {
      return enUS
    } else if (locale === 'it_IT') {
      return zhCN
    }
  }
  window.scrollTo(0, 0)
  window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault()
    setInstallApp(true)
    console.log('You can install the app now!ssdsdsd')
  })

  return (
    <ConfigProvider
      locale={getAntdLocale()}
      componentSize="middle"
      theme={{ token: { colorPrimary: variables?.primary, fontFamily: 'Noto Sans, sans-serif'}, algorithm: theme === 'dark' ? a.defaultAlgorithm : a.defaultAlgorithm }}
    >
      <IntlProvider locale={locale.split('_')[0]} messages={localeConfig[locale]}>
        <Suspense fallback={null}>
          <Spin
            spinning={loading}
            className="app-loading-wrapper"
            tip={<LocaleFormatter id="global.loading" />}
          ></Spin>
          { RenderRouter() }
          {
            installedApp ? <Modal
              title={<></>}
              centered
              open={true}
              width={450}
              footer={<div></div>}
              className={'modal-logout'}
              closeIcon={<></>}
            >
              <Title level={3} style={{ textAlign: 'center'}}>Install App</Title>
              <p>Please install <b>Vikas Tiles</b> app for a better experience.</p>
              <div className={'button-control-modal'}>
                <Button block loading={installing} onClick={ () => {
                  installApp()
                  setInstalling(true)
                  setTimeout(() => {
                    window.location.reload()
                  }, 7000)
                } } type='primary' size='large'> { installing ? 'Installing' : 'Install' } </Button>
              </div>
            </Modal>
              : null
          }
        </Suspense>
      </IntlProvider>
    </ConfigProvider>
  )
}
export default App
