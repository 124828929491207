import { combineReducers } from '@reduxjs/toolkit'
import globalReducer from './store/global.store'
import userReducer from './store/user.store'
import PropertyReducer from './store/property.store'

const rootReducer = combineReducers({
  user: userReducer,
  global: globalReducer,
  property: PropertyReducer,
})

export default rootReducer
