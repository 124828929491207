import { useRef, useEffect } from 'react'

function useDocumentTitle(title: any, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title)
  const fullTitle: any = title ? title + ' | ' + process.env.REACT_APP_NAME : process.env.REACT_APP_NAME
  useEffect(() => {
    document.title = fullTitle
  }, [title])

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current
    }
  }, [prevailOnUnmount])
}

export default useDocumentTitle
