import React, { useState, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { RootStateOrAny, useSelector } from 'react-redux'

const AuthGuard: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { logged } = useSelector((state: RootStateOrAny) => state.user)

  const isAuthenticated = logged
  const [previouseRoute, setPreviousRoute] = useState<string | null>(null)
  const { pathname } = useLocation()
  const authenticated = isAuthenticated

  useEffect(() => {
    if (previouseRoute !== null) setPreviousRoute(pathname)
  }, [pathname, previouseRoute])

  if (authenticated) return <>{children}</>
  else {
    return (
      <Navigate
        to='/login'
        state={{ redirectUrl: previouseRoute }}
      />
      // <Redirect
      //     to={{
      //         pathname: '/signin',
      //         state: { redirectUrl: previouseRoute },
      //     }}
      // />
    )
  }
}

export default AuthGuard
