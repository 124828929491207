import { lazy } from 'react'
import Loadable from '../components/common/loader/Loadable'
import { Navigate } from 'react-router'
import AuthGuard from './AuthGuard'
// const ProductList = Loadable(lazy(() => import('../pages/home/list')))
const Home = Loadable(lazy(() => import('../pages/home/Home')))
const Collections = Loadable(lazy(() => import('../pages/collections/index')))
const ProductPage = Loadable(lazy(() => import('../pages/product/index')))

const privateRoutes = [
  {
    path: '/',
    children: [{
      path: '/',
      element: <Navigate to="home" />
    },
    {
      path: '/home',
      element: <AuthGuard> <Home /> </AuthGuard>
    },
    {
      path: '/list/id?',
      element: <AuthGuard> <Collections /> </AuthGuard>
    },
    {
      path: '/list/product/id?',
      element: <AuthGuard> <ProductPage /> </AuthGuard>
    },
    ]
  }
]

export default privateRoutes
